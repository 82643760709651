"use strict"

require("../scss/index.scss")
require('slick-carousel')

import $ from "jquery"
import { CountUp } from 'countup.js'

import ScrollMagic from 'scrollmagic'
import { TweenMax, TimelineMax } from "gsap"
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

/* start jQuery */

/* Menu action */
$(".header-nav-item a").on("click", (e) => {
    e.preventDefault
    let hash = e.currentTarget.attributes["href"].value
    let pos = $(hash).offset().top
    pos -= 100;
    $('html,body').animate({scrollTop: pos}, 360);
    if($("#siteWrapper").hasClass("menuopen")){
        $("#siteWrapper").removeClass("menuopen")
        $(".hamburger").removeClass("is-active")
    }
});  

/* Start Slider 1 */
$('#slider1').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
     }
    ]
});
/* Start Slider 1 */
$('#slider2').slick({
    infinite: true,
    dots: false,
    autoplay: false
});

//ACC_toogle Aufklapper
$("a.acc_title").on("click", (e) => {
    e.preventDefault
    let target = e.currentTarget.attributes["href"].value
    $(target).toggleClass("open");
    $(target+ " figcaption").slideToggle(600);
    return false
});


//toggle menu
$('.hamburger').on("click", function(){
    $(".hamburger").toggleClass("is-active");
    $('#siteWrapper').toggleClass("menuopen");
    return false;
});
$('.burger_nav_overlay').on("click",function(){
    $('.hamburger').toggleClass("is-active");
    $('#siteWrapper').toggleClass("menuopen");
    return false;
});

//Tooltip Grafik
$('.floatover').hover(function(e){
    $(".tooltip-text-wrapper div").hide();
    let target = e.currentTarget.attributes["data-target"].value;
    $("#"+target).fadeIn(500);

}, function(e){
    let target = e.currentTarget.attributes["data-target"].value;
    console.log("leave", target);
});


//Countup Config
const counterItems = {
    c1: 1332,
    c2: 1160,
    c3: 400,
    c4: 150,
    c5: 6040,
    c6: 6
}
const options = {
    separator: ".",
    duration: 10
}
const numContainers = document.querySelectorAll(".counter-number")
let index = 1

const startcounter = () => {
    for (let i=0; i < numContainers.length; i++){
        const countUp = new CountUp('c'+index, counterItems['c'+index], options);
        if (!countUp.error) {
            console.log("init for c"+index)
            countUp.start()
            //window.addEventListener('DOMContentLoaded', () => {  countUp.handleScroll(); })
        } else {
            console.error(countUp.error);
        }
        index++
    }
}

$(function () { 
    const triggerpos = $("#counter").position();
    console.log("triggerpos:", triggerpos.top - 100)
    $(document).on("scroll", function() {

        var top = $(document).scrollTop();
        //console.log(top)

        if (top > triggerpos.top) {
            console.log("trigger")
            startcounter()
            $(document).off('scroll')
        }

    });

    // init controller
    const controller = new ScrollMagic.Controller({globalSceneOptions: {duration: 1100}});

    // build scenes
    //new ScrollMagic.Scene({triggerElement: "#banner1"}).setClassToggle("#bannerZoom1", "active").addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#banner2"}).setClassToggle("#bannerZoom2", "active").addTo(controller);

    new ScrollMagic.Scene({triggerElement: "#grafik1"}).setClassToggle("#grafik1", "active").addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#grafik2"}).setClassToggle("#grafik2", "active").addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#grafik3"}).setClassToggle("#grafik3", "active").addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#grafik4"}).setClassToggle("#grafik4", "active").addTo(controller);

    // Play video Grunstein
    const gsVideo = document.getElementById('gsVideo');
	var scene = new ScrollMagic.Scene({triggerElement: "#grundstein", duration: 200})
    .addTo(controller)
    .on("enter", function () {
        gsVideo.play();
      })
      .on("leave", function () {
        gsVideo.pause();
      })
});




